export const languagesList = [
	{ value: "English", label: "English" },
	{ value: "hrvatski", label: "hrvatski" },
	{ value: "svenska", label: "svenska" },
	{ value: "Dansk", label: "Dansk" },
	{ value: "Nederlands", label: "Nederlands" },
	{ value: "eesti Keel", label: "eesti Keel" },
	{ value: "soumi", label: "Soumi" },
	{ value: "français", label: "français" },
	{ value: "Deutsch", label: "Deutsch" },
	{ value: "magyar", label: "magyar" },
	{ value: "Islenska", label: "Islenska" },
	{ value: "Gaeilge", label: "Gaeilge" },
	{ value: "italiano", label: "italiano" },
	{ value: "Malti", label: "Malti" },
	{ value: "norsk", label: "norsk" },
	{ value: "polski", label: "polski" },
	{ value: "Português", label: "Português" },
	{ value: "romana", label: "romana" },
	{ value: "srpski", label: "srpski" },
	{ value: "Slovenčina", label: "Slovenčina" },
	{ value: "Română", label: "Română" },
	{ value: "Español", label: "Español" },
	{ value: "Lingala", label: "Lingala" },
	{ value: "Irish", label: "Irish" },
	{ value: "Hungarian", label: "Hungarian" },
	{ value: "Turkish", label: "Turkish" },
	{ value: "Greek", label: "Greek" },
	{ value: "Polish", label: "Polish" },
	{ value: "Norwegian", label: "Norwegian" },
	{ value: "Swedish", label: "Swedish" },
	{ value: "Portuguese", label: "Portuguese" },
	{ value: "German", label: "German" },
	{ value: "French", label: "French" },
	{ value: "Spanish", label: "Spanish" },
	{ value: "Italian", label: "Italian" },
];
export const dateOfDDList = [
	{ value: "01", label: "01" },
	{ value: "02", label: "02" },
	{ value: "03", label: "03" },
	{ value: "04", label: "04" },
	{ value: "05", label: "05" },
	{ value: "06", label: "06" },
	{ value: "07", label: "07" },
	{ value: "08", label: "08" },
	{ value: "09", label: "09" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
	{ value: "13", label: "13" },
	{ value: "14", label: "14" },
	{ value: "15", label: "15" },
	{ value: "16", label: "16" },
	{ value: "17", label: "17" },
	{ value: "18", label: "18" },
	{ value: "19", label: "19" },
	{ value: "20", label: "20" },
	{ value: "21", label: "21" },
	{ value: "22", label: "22" },
	{ value: "23", label: "23" },
	{ value: "24", label: "24" },
	{ value: "25", label: "25" },
	{ value: "26", label: "26" },
	{ value: "27", label: "27" },
	{ value: "28", label: "28" },
	{ value: "29", label: "29" },
	{ value: "30", label: "30" },
	{ value: "31", label: "31" },
];
export const dateOfMMList = [
	{ value: "01", label: "01" },
	{ value: "02", label: "02" },
	{ value: "03", label: "03" },
	{ value: "04", label: "04" },
	{ value: "05", label: "05" },
	{ value: "06", label: "06" },
	{ value: "07", label: "07" },
	{ value: "08", label: "08" },
	{ value: "09", label: "09" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
];
export const dateOfYYList = [
	{ value: "1925", label: "1925" },
	{ value: "1926", label: "1926" },
	{ value: "1927", label: "1927" },
	{ value: "1928", label: "1928" },
	{ value: "1929", label: "1929" },
	{ value: "1930", label: "1930" },
	{ value: "1931", label: "1931" },
	{ value: "1932", label: "1932" },
	{ value: "1933", label: "1933" },
	{ value: "1934", label: "1934" },
	{ value: "1935", label: "1935" },
	{ value: "1936", label: "1936" },
	{ value: "1937", label: "1937" },
	{ value: "1938", label: "1938" },
	{ value: "1939", label: "1939" },
	{ value: "1940", label: "1940" },
	{ value: "1941", label: "1941" },
	{ value: "1942", label: "1942" },
	{ value: "1943", label: "1943" },
	{ value: "1944", label: "1944" },
	{ value: "1945", label: "1945" },
	{ value: "1946", label: "1946" },
	{ value: "1945", label: "1945" },
	{ value: "1946", label: "1946" },
	{ value: "1947", label: "11947" },
	{ value: "1948", label: "1948" },
	{ value: "1949", label: "1949" },
	{ value: "1950", label: "1950" },
	{ value: "1951", label: "1951" },
	{ value: "1952", label: "1952" },
	{ value: "1953", label: "1953" },
	{ value: "1954", label: "1954" },
	{ value: "1955", label: "1955" },
	{ value: "1956", label: "1956" },
	{ value: "1957", label: "1957" },
	{ value: "1958", label: "1958" },
	{ value: "1959", label: "1959" },
	{ value: "1960", label: "1960" },
	{ value: "1961", label: "1961" },
	{ value: "1962", label: "1962" },
	{ value: "1963", label: "1963" },
	{ value: "1964", label: "1964" },
	{ value: "1965", label: "1965" },
	{ value: "1966", label: "1966" },
	{ value: "1967", label: "1967" },
	{ value: "1968", label: "1968" },
	{ value: "1969", label: "1969" },
	{ value: "1970", label: "1970" },
	{ value: "1971", label: "1971" },
	{ value: "1972", label: "1972" },
	{ value: "1973", label: "1973" },
	{ value: "1974", label: "1974" },
	{ value: "1975", label: "1975" },
	{ value: "1976", label: "1976" },
	{ value: "1977", label: "1977" },
	{ value: "1978", label: "1978" },
	{ value: "1979", label: "1979" },
	{ value: "1980", label: "1980" },
	{ value: "1981", label: "1981" },
	{ value: "1982", label: "1982" },
	{ value: "1983", label: "1983" },
	{ value: "1984", label: "1984" },
	{ value: "1985", label: "1985" },
	{ value: "1986", label: "1986" },
	{ value: "1987", label: "1987" },
	{ value: "1988", label: "1988" },
	{ value: "1989", label: "1989" },
	{ value: "1990", label: "1990" },
	{ value: "1991", label: "1991" },
	{ value: "1992", label: "1992" },
	{ value: "1992", label: "1992" },
	{ value: "1993", label: "1993" },
	{ value: "1994", label: "1994" },
	{ value: "1995", label: "1995" },
	{ value: "1996", label: "1996" },
	{ value: "1997", label: "1997" },
	{ value: "1998", label: "1998" },
	{ value: "1999", label: "1999" },
	{ value: "2000", label: "2000" },
	{ value: "2001", label: "2001" },
	{ value: "2002", label: "2002" },
	{ value: "2003", label: "2003" },
	{ value: "2004", label: "2004" },
	{ value: "2005", label: "2005" },
	{ value: "2006", label: "2006" },
	{ value: "2007", label: "2007" },
	{ value: "2008", label: "2008" },
	{ value: "2009", label: "2009" },
	{ value: "2010", label: "2010" },
	{ value: "2011", label: "2011" },
	{ value: "2012", label: "2012" },
	{ value: "2013", label: "2013" },
	{ value: "2014", label: "2014" },
	{ value: "2015", label: "2015" },
	{ value: "2016", label: "2016" },
	{ value: "2017", label: "2017" },
	{ value: "2018", label: "2018" },
	{ value: "2019", label: "2019" },
	{ value: "2020", label: "2020" },
	{ value: "2021", label: "2021" },
	{ value: "2022", label: "2022" },
	{ value: "2023", label: "2023" },
	{ value: "2024", label: "2024" },
];
export const dateOfYYListUp = [
	{ value: "2024", label: "2024" },
	{ value: "2025", label: "2025" },
	{ value: "2026", label: "2026" },
	{ value: "2027", label: "2027" },
	{ value: "2028", label: "2028" },
	{ value: "2029", label: "2029" },
	{ value: "2030", label: "2030" },
	{ value: "2031", label: "2031" },
	{ value: "2032", label: "2032" },
	{ value: "2033", label: "2033" },
	{ value: "2034", label: "2034" },
	{ value: "2035", label: "2035" },
	{ value: "2036", label: "2036" },
	{ value: "2037", label: "2037" },
	{ value: "2038", label: "2038" },
	{ value: "2039", label: "2039" },
	{ value: "2040", label: "2040" },
	{ value: "2041", label: "2041" },
	{ value: "2042", label: "2042" },
	{ value: "2043", label: "2043" },
	{ value: "2044", label: "2044" },
	{ value: "2045", label: "2045" },
	{ value: "2046", label: "2046" },
	{ value: "2047", label: "2047" },
	{ value: "2048", label: "2048" },
	{ value: "2049", label: "2049" },
	{ value: "2050", label: "2050" },
	{ value: "2051", label: "2051" },
	{ value: "2052", label: "2052" },
	{ value: "2053", label: "2053" },
	{ value: "2054", label: "2054" },
	{ value: "2055", label: "2055" },
	{ value: "2056", label: "2056" },
	{ value: "2057", label: "2057" },
	{ value: "2058", label: "2058" },
	{ value: "2059", label: "2059" },
	{ value: "2060", label: "2060" },
];

export const genderList = [
	{ value: "Male", label: "Male" },
	{ value: "Female", label: "Female" },
	{ value: "Other", label: "Other" },
	{ value: "Do Not Indicate", llabel: "Do Not Indicate" },
];
export const addresslist = [
	{ value: "Home", label: "Home" },
	{ value: "Work", label: "Work" },
	{ value: "Other", label: "Other" },
];
export const contactlist = [
	{ value: "Home", label: "Home" },
	{ value: "Work", label: "Work" },
	{ value: "Mobile", label: "Mobile" },
	{ value: "Other", label: "Other" },
];
export const DateFormatType = [
	{ value: "24/04/2021", label: "24/04/2021" },
	{ value: "24/4/2021", label: "24/04/2021" },
	{ value: "24/4/21", label: "24/4/21" },
	{ value: "24/April/2021", label: "24/April/2021" },
	{ value: "04/24/2021", label: "04/24/2021" },
];
export const citizenShipStatus = [
	{ value: "U.S. dual citizen", label: "U.S. dual citizen" },
	{ value: "U.S. permanent resident", label: "U.S. permanent resident" },
	{ value: "U.S. refuges asylee", label: "U.S. refuges asylee" },
	{
		value: "DACA,undocumented,Deferred Enforced Departure,or Temporary Status",
		label: "DACA,undocumented,Deferred Enforced Departure,or Temporary Status",
	},
	{ value: "Citizen of non-U.S. country", label: "Citizen of non-U.S. country" },
];
export const countryCode = [
	{
		name: "Afghanistan",
		dial_code: "+93",
		code: "AF",
	},
	{
		name: "Aland Islands",
		dial_code: "+358",
		code: "AX",
	},
	{
		name: "Albania",
		dial_code: "+355",
		code: "AL",
	},
	{
		name: "Algeria",
		dial_code: "+213",
		code: "DZ",
	},
	{
		name: "AmericanSamoa",
		dial_code: "+1684",
		code: "AS",
	},
	{
		name: "Andorra",
		dial_code: "+376",
		code: "AD",
	},
	{
		name: "Angola",
		dial_code: "+244",
		code: "AO",
	},
	{
		name: "Anguilla",
		dial_code: "+1264",
		code: "AI",
	},
	{
		name: "Antarctica",
		dial_code: "+672",
		code: "AQ",
	},
	{
		name: "Antigua and Barbuda",
		dial_code: "+1268",
		code: "AG",
	},
	{
		name: "Argentina",
		dial_code: "+54",
		code: "AR",
	},
	{
		name: "Armenia",
		dial_code: "+374",
		code: "AM",
	},
	{
		name: "Aruba",
		dial_code: "+297",
		code: "AW",
	},
	{
		name: "Australia",
		dial_code: "+61",
		code: "AU",
	},
	{
		name: "Austria",
		dial_code: "+43",
		code: "AT",
	},
	{
		name: "Azerbaijan",
		dial_code: "+994",
		code: "AZ",
	},
	{
		name: "Bahamas",
		dial_code: "+1242",
		code: "BS",
	},
	{
		name: "Bahrain",
		dial_code: "+973",
		code: "BH",
	},
	{
		name: "Bangladesh",
		dial_code: "+880",
		code: "BD",
	},
	{
		name: "Barbados",
		dial_code: "+1246",
		code: "BB",
	},
	{
		name: "Belarus",
		dial_code: "+375",
		code: "BY",
	},
	{
		name: "Belgium",
		dial_code: "+32",
		code: "BE",
	},
	{
		name: "Belize",
		dial_code: "+501",
		code: "BZ",
	},
	{
		name: "Benin",
		dial_code: "+229",
		code: "BJ",
	},
	{
		name: "Bermuda",
		dial_code: "+1441",
		code: "BM",
	},
	{
		name: "Bhutan",
		dial_code: "+975",
		code: "BT",
	},
	{
		name: "Bolivia, Plurinational State of",
		dial_code: "+591",
		code: "BO",
	},
	{
		name: "Bosnia and Herzegovina",
		dial_code: "+387",
		code: "BA",
	},
	{
		name: "Botswana",
		dial_code: "+267",
		code: "BW",
	},
	{
		name: "Brazil",
		dial_code: "+55",
		code: "BR",
	},
	{
		name: "British Indian Ocean Territory",
		dial_code: "+246",
		code: "IO",
	},
	{
		name: "Brunei Darussalam",
		dial_code: "+673",
		code: "BN",
	},
	{
		name: "Bulgaria",
		dial_code: "+359",
		code: "BG",
	},
	{
		name: "Burkina Faso",
		dial_code: "+226",
		code: "BF",
	},
	{
		name: "Burundi",
		dial_code: "+257",
		code: "BI",
	},
	{
		name: "Cambodia",
		dial_code: "+855",
		code: "KH",
	},
	{
		name: "Cameroon",
		dial_code: "+237",
		code: "CM",
	},
	{
		name: "Canada",
		dial_code: "+ 1",
		code: "CA",
	},
	{
		name: "Cape Verde",
		dial_code: "+238",
		code: "CV",
	},
	{
		name: "Cayman Islands",
		dial_code: "+ 345",
		code: "KY",
	},
	{
		name: "Central African Republic",
		dial_code: "+236",
		code: "CF",
	},
	{
		name: "Chad",
		dial_code: "+235",
		code: "TD",
	},
	{
		name: "Chile",
		dial_code: "+56",
		code: "CL",
	},
	{
		name: "China",
		dial_code: "+86",
		code: "CN",
	},
	{
		name: "Christmas Island",
		dial_code: "+61",
		code: "CX",
	},
	{
		name: "Cocos (Keeling) Islands",
		dial_code: "+61",
		code: "CC",
	},
	{
		name: "Colombia",
		dial_code: "+57",
		code: "CO",
	},
	{
		name: "Comoros",
		dial_code: "+269",
		code: "KM",
	},
	{
		name: "Congo",
		dial_code: "+242",
		code: "CG",
	},
	{
		name: "Congo, The Democratic Republic of the Congo",
		dial_code: "+243",
		code: "CD",
	},
	{
		name: "Cook Islands",
		dial_code: "+682",
		code: "CK",
	},
	{
		name: "Costa Rica",
		dial_code: "+506",
		code: "CR",
	},
	{
		name: "Cote d'Ivoire",
		dial_code: "+225",
		code: "CI",
	},
	{
		name: "Croatia",
		dial_code: "+385",
		code: "HR",
	},
	{
		name: "Cuba",
		dial_code: "+53",
		code: "CU",
	},
	{
		name: "Cyprus",
		dial_code: "+357",
		code: "CY",
	},
	{
		name: "Czech Republic",
		dial_code: "+420",
		code: "CZ",
	},
	{
		name: "Denmark",
		dial_code: "+45",
		code: "DK",
	},
	{
		name: "Djibouti",
		dial_code: "+253",
		code: "DJ",
	},
	{
		name: "Dominica",
		dial_code: "+1767",
		code: "DM",
	},
	{
		name: "Dominican Republic",
		dial_code: "+1849",
		code: "DO",
	},
	{
		name: "Ecuador",
		dial_code: "+593",
		code: "EC",
	},
	{
		name: "Egypt",
		dial_code: "+20",
		code: "EG",
	},
	{
		name: "El Salvador",
		dial_code: "+503",
		code: "SV",
	},
	{
		name: "Equatorial Guinea",
		dial_code: "+240",
		code: "GQ",
	},
	{
		name: "Eritrea",
		dial_code: "+291",
		code: "ER",
	},
	{
		name: "Estonia",
		dial_code: "+372",
		code: "EE",
	},
	{
		name: "Ethiopia",
		dial_code: "+251",
		code: "ET",
	},
	{
		name: "Falkland Islands (Malvinas)",
		dial_code: "+500",
		code: "FK",
	},
	{
		name: "Faroe Islands",
		dial_code: "+298",
		code: "FO",
	},
	{
		name: "Fiji",
		dial_code: "+679",
		code: "FJ",
	},
	{
		name: "Finland",
		dial_code: "+358",
		code: "FI",
	},
	{
		name: "France",
		dial_code: "+33",
		code: "FR",
	},
	{
		name: "French Guiana",
		dial_code: "+594",
		code: "GF",
	},
	{
		name: "French Polynesia",
		dial_code: "+689",
		code: "PF",
	},
	{
		name: "Gabon",
		dial_code: "+241",
		code: "GA",
	},
	{
		name: "Gambia",
		dial_code: "+220",
		code: "GM",
	},
	{
		name: "Georgia",
		dial_code: "+995",
		code: "GE",
	},
	{
		name: "Germany",
		dial_code: "+49",
		code: "DE",
	},
	{
		name: "Ghana",
		dial_code: "+233",
		code: "GH",
	},
	{
		name: "Gibraltar",
		dial_code: "+350",
		code: "GI",
	},
	{
		name: "Greece",
		dial_code: "+30",
		code: "GR",
	},
	{
		name: "Greenland",
		dial_code: "+299",
		code: "GL",
	},
	{
		name: "Grenada",
		dial_code: "+1473",
		code: "GD",
	},
	{
		name: "Guadeloupe",
		dial_code: "+590",
		code: "GP",
	},
	{
		name: "Guam",
		dial_code: "+1671",
		code: "GU",
	},
	{
		name: "Guatemala",
		dial_code: "+502",
		code: "GT",
	},
	{
		name: "Guernsey",
		dial_code: "+44",
		code: "GG",
	},
	{
		name: "Guinea",
		dial_code: "+224",
		code: "GN",
	},
	{
		name: "Guinea-Bissau",
		dial_code: "+245",
		code: "GW",
	},
	{
		name: "Guyana",
		dial_code: "+595",
		code: "GY",
	},
	{
		name: "Haiti",
		dial_code: "+509",
		code: "HT",
	},
	{
		name: "Holy See (Vatican City State)",
		dial_code: "+379",
		code: "VA",
	},
	{
		name: "Honduras",
		dial_code: "+504",
		code: "HN",
	},
	{
		name: "Hong Kong",
		dial_code: "+852",
		code: "HK",
	},
	{
		name: "Hungary",
		dial_code: "+36",
		code: "HU",
	},
	{
		name: "Iceland",
		dial_code: "+354",
		code: "IS",
	},
	{
		name: "India",
		dial_code: "+91",
		code: "IN",
	},
	{
		name: "Indonesia",
		dial_code: "+62",
		code: "ID",
	},
	{
		name: "Iran, Islamic Republic of Persian Gulf",
		dial_code: "+98",
		code: "IR",
	},
	{
		name: "Iraq",
		dial_code: "+964",
		code: "IQ",
	},
	{
		name: "Ireland",
		dial_code: "+353",
		code: "IE",
	},
	{
		name: "Isle of Man",
		dial_code: "+44",
		code: "IM",
	},
	{
		name: "Israel",
		dial_code: "+972",
		code: "IL",
	},
	{
		name: "Italy",
		dial_code: "+39",
		code: "IT",
	},
	{
		name: "Jamaica",
		dial_code: "+1876",
		code: "JM",
	},
	{
		name: "Japan",
		dial_code: "+81",
		code: "JP",
	},
	{
		name: "Jersey",
		dial_code: "+44",
		code: "JE",
	},
	{
		name: "Jordan",
		dial_code: "+962",
		code: "JO",
	},
	{
		name: "Kazakhstan",
		dial_code: "+77",
		code: "KZ",
	},
	{
		name: "Kenya",
		dial_code: "+254",
		code: "KE",
	},
	{
		name: "Kiribati",
		dial_code: "+686",
		code: "KI",
	},
	{
		name: "Korea, Democratic People's Republic of Korea",
		dial_code: "+850",
		code: "KP",
	},
	{
		name: "Korea, Republic of South Korea",
		dial_code: "+82",
		code: "KR",
	},
	{
		name: "Kuwait",
		dial_code: "+965",
		code: "KW",
	},
	{
		name: "Kyrgyzstan",
		dial_code: "+996",
		code: "KG",
	},
	{
		name: "Laos",
		dial_code: "+856",
		code: "LA",
	},
	{
		name: "Latvia",
		dial_code: "+371",
		code: "LV",
	},
	{
		name: "Lebanon",
		dial_code: "+961",
		code: "LB",
	},
	{
		name: "Lesotho",
		dial_code: "+266",
		code: "LS",
	},
	{
		name: "Liberia",
		dial_code: "+231",
		code: "LR",
	},
	{
		name: "Libyan Arab Jamahiriya",
		dial_code: "+218",
		code: "LY",
	},
	{
		name: "Liechtenstein",
		dial_code: "+423",
		code: "LI",
	},
	{
		name: "Lithuania",
		dial_code: "+370",
		code: "LT",
	},
	{
		name: "Luxembourg",
		dial_code: "+352",
		code: "LU",
	},
	{
		name: "Macao",
		dial_code: "+853",
		code: "MO",
	},
	{
		name: "Macedonia",
		dial_code: "+389",
		code: "MK",
	},
	{
		name: "Madagascar",
		dial_code: "+261",
		code: "MG",
	},
	{
		name: "Malawi",
		dial_code: "+265",
		code: "MW",
	},
	{
		name: "Malaysia",
		dial_code: "+60",
		code: "MY",
	},
	{
		name: "Maldives",
		dial_code: "+960",
		code: "MV",
	},
	{
		name: "Mali",
		dial_code: "+223",
		code: "ML",
	},
	{
		name: "Malta",
		dial_code: "+356",
		code: "MT",
	},
	{
		name: "Marshall Islands",
		dial_code: "+692",
		code: "MH",
	},
	{
		name: "Martinique",
		dial_code: "+596",
		code: "MQ",
	},
	{
		name: "Mauritania",
		dial_code: "+222",
		code: "MR",
	},
	{
		name: "Mauritius",
		dial_code: "+230",
		code: "MU",
	},
	{
		name: "Mayotte",
		dial_code: "+262",
		code: "YT",
	},
	{
		name: "Mexico",
		dial_code: "+52",
		code: "MX",
	},
	{
		name: "Micronesia, Federated States of Micronesia",
		dial_code: "+691",
		code: "FM",
	},
	{
		name: "Moldova",
		dial_code: "+373",
		code: "MD",
	},
	{
		name: "Monaco",
		dial_code: "+377",
		code: "MC",
	},
	{
		name: "Mongolia",
		dial_code: "+976",
		code: "MN",
	},
	{
		name: "Montenegro",
		dial_code: "+382",
		code: "ME",
	},
	{
		name: "Montserrat",
		dial_code: "+1664",
		code: "MS",
	},
	{
		name: "Morocco",
		dial_code: "+212",
		code: "MA",
	},
	{
		name: "Mozambique",
		dial_code: "+258",
		code: "MZ",
	},
	{
		name: "Myanmar",
		dial_code: "+95",
		code: "MM",
	},
	{
		name: "Namibia",
		dial_code: "+264",
		code: "NA",
	},
	{
		name: "Nauru",
		dial_code: "+674",
		code: "NR",
	},
	{
		name: "Nepal",
		dial_code: "+977",
		code: "NP",
	},
	{
		name: "Netherlands",
		dial_code: "+31",
		code: "NL",
	},
	{
		name: "Netherlands Antilles",
		dial_code: "+599",
		code: "AN",
	},
	{
		name: "New Caledonia",
		dial_code: "+687",
		code: "NC",
	},
	{
		name: "New Zealand",
		dial_code: "+64",
		code: "NZ",
	},
	{
		name: "Nicaragua",
		dial_code: "+505",
		code: "NI",
	},
	{
		name: "Niger",
		dial_code: "+227",
		code: "NE",
	},
	{
		name: "Nigeria",
		dial_code: "+234",
		code: "NG",
	},
	{
		name: "Niue",
		dial_code: "+683",
		code: "NU",
	},
	{
		name: "Norfolk Island",
		dial_code: "+672",
		code: "NF",
	},
	{
		name: "Northern Mariana Islands",
		dial_code: "+1670",
		code: "MP",
	},
	{
		name: "Norway",
		dial_code: "+47",
		code: "NO",
	},
	{
		name: "Oman",
		dial_code: "+968",
		code: "OM",
	},
	{
		name: "Pakistan",
		dial_code: "+92",
		code: "PK",
	},
	{
		name: "Palau",
		dial_code: "+680",
		code: "PW",
	},
	{
		name: "Palestinian Territory, Occupied",
		dial_code: "+970",
		code: "PS",
	},
	{
		name: "Panama",
		dial_code: "+507",
		code: "PA",
	},
	{
		name: "Papua New Guinea",
		dial_code: "+675",
		code: "PG",
	},
	{
		name: "Paraguay",
		dial_code: "+595",
		code: "PY",
	},
	{
		name: "Peru",
		dial_code: "+51",
		code: "PE",
	},
	{
		name: "Philippines",
		dial_code: "+63",
		code: "PH",
	},
	{
		name: "Pitcairn",
		dial_code: "+872",
		code: "PN",
	},
	{
		name: "Poland",
		dial_code: "+48",
		code: "PL",
	},
	{
		name: "Portugal",
		dial_code: "+351",
		code: "PT",
	},
	{
		name: "Puerto Rico",
		dial_code: "+1939",
		code: "PR",
	},
	{
		name: "Qatar",
		dial_code: "+974",
		code: "QA",
	},
	{
		name: "Romania",
		dial_code: "+40",
		code: "RO",
	},
	{
		name: "Russia",
		dial_code: "+7",
		code: "RU",
	},
	{
		name: "Rwanda",
		dial_code: "+250",
		code: "RW",
	},
	{
		name: "Reunion",
		dial_code: "+262",
		code: "RE",
	},
	{
		name: "Saint Barthelemy",
		dial_code: "+590",
		code: "BL",
	},
	{
		name: "Saint Helena, Ascension and Tristan Da Cunha",
		dial_code: "+290",
		code: "SH",
	},
	{
		name: "Saint Kitts and Nevis",
		dial_code: "+1869",
		code: "KN",
	},
	{
		name: "Saint Lucia",
		dial_code: "+1758",
		code: "LC",
	},
	{
		name: "Saint Martin",
		dial_code: "+590",
		code: "MF",
	},
	{
		name: "Saint Pierre and Miquelon",
		dial_code: "+508",
		code: "PM",
	},
	{
		name: "Saint Vincent and the Grenadines",
		dial_code: "+1784",
		code: "VC",
	},
	{
		name: "Samoa",
		dial_code: "+685",
		code: "WS",
	},
	{
		name: "San Marino",
		dial_code: "+378",
		code: "SM",
	},
	{
		name: "Sao Tome and Principe",
		dial_code: "+239",
		code: "ST",
	},
	{
		name: "Saudi Arabia",
		dial_code: "+966",
		code: "SA",
	},
	{
		name: "Senegal",
		dial_code: "+221",
		code: "SN",
	},
	{
		name: "Serbia",
		dial_code: "+381",
		code: "RS",
	},
	{
		name: "Seychelles",
		dial_code: "+248",
		code: "SC",
	},
	{
		name: "Sierra Leone",
		dial_code: "+232",
		code: "SL",
	},
	{
		name: "Singapore",
		dial_code: "+65",
		code: "SG",
	},
	{
		name: "Slovakia",
		dial_code: "+421",
		code: "SK",
	},
	{
		name: "Slovenia",
		dial_code: "+386",
		code: "SI",
	},
	{
		name: "Solomon Islands",
		dial_code: "+677",
		code: "SB",
	},
	{
		name: "Somalia",
		dial_code: "+252",
		code: "SO",
	},
	{
		name: "South Africa",
		dial_code: "+27",
		code: "ZA",
	},
	{
		name: "South Sudan",
		dial_code: "+211",
		code: "SS",
	},
	{
		name: "South Georgia and the South Sandwich Islands",
		dial_code: "+500",
		code: "GS",
	},
	{
		name: "Spain",
		dial_code: "+34",
		code: "ES",
	},
	{
		name: "Sri Lanka",
		dial_code: "+94",
		code: "LK",
	},
	{
		name: "Sudan",
		dial_code: "+249",
		code: "SD",
	},
	{
		name: "Surname",
		dial_code: "+597",
		code: "SR",
	},
	{
		name: "Svalbard and Jan Mayen",
		dial_code: "+47",
		code: "SJ",
	},
	{
		name: "Swaziland",
		dial_code: "+268",
		code: "SZ",
	},
	{
		name: "Sweden",
		dial_code: "+46",
		code: "SE",
	},
	{
		name: "Switzerland",
		dial_code: "+41",
		code: "CH",
	},
	{
		name: "Syrian Arab Republic",
		dial_code: "+963",
		code: "SY",
	},
	{
		name: "Taiwan",
		dial_code: "+886",
		code: "TW",
	},
	{
		name: "Tajikistan",
		dial_code: "+992",
		code: "TJ",
	},
	{
		name: "Tanzania, United Republic of Tanzania",
		dial_code: "+255",
		code: "TZ",
	},
	{
		name: "Thailand",
		dial_code: "+66",
		code: "TH",
	},
	{
		name: "Timor-Leste",
		dial_code: "+670",
		code: "TL",
	},
	{
		name: "Togo",
		dial_code: "+228",
		code: "TG",
	},
	{
		name: "Tokelau",
		dial_code: "+690",
		code: "TK",
	},
	{
		name: "Tonga",
		dial_code: "+676",
		code: "TO",
	},
	{
		name: "Trinidad and Tobago",
		dial_code: "+1868",
		code: "TT",
	},
	{
		name: "Tunisia",
		dial_code: "+216",
		code: "TN",
	},
	{
		name: "Turkey",
		dial_code: "+90",
		code: "TR",
	},
	{
		name: "Turkmenistan",
		dial_code: "+993",
		code: "TM",
	},
	{
		name: "Turks and Caicos Islands",
		dial_code: "+1649",
		code: "TC",
	},
	{
		name: "Tuvalu",
		dial_code: "+688",
		code: "TV",
	},
	{
		name: "Uganda",
		dial_code: "+256",
		code: "UG",
	},
	{
		name: "Ukraine",
		dial_code: "+380",
		code: "UA",
	},
	{
		name: "United Arab Emirates",
		dial_code: "+971",
		code: "AE",
	},
	{
		name: "United Kingdom",
		dial_code: "+44",
		code: "GB",
	},
	{
		name: "United States",
		dial_code: "+1",
		code: "US",
	},
	{
		name: "Uruguay",
		dial_code: "+598",
		code: "UY",
	},
	{
		name: "Uzbekistan",
		dial_code: "+998",
		code: "UZ",
	},
	{
		name: "Vanuatu",
		dial_code: "+678",
		code: "VU",
	},
	{
		name: "Venezuela, Bolivarian Republic of Venezuela",
		dial_code: "+58",
		code: "VE",
	},
	{
		name: "Vietnam",
		dial_code: "+84",
		code: "VN",
	},
	{
		name: "Virgin Islands, British",
		dial_code: "+1284",
		code: "VG",
	},
	{
		name: "Virgin Islands, U.S.",
		dial_code: "+1340",
		code: "VI",
	},
	{
		name: "Wallis and Futuna",
		dial_code: "+681",
		code: "WF",
	},
	{
		name: "Yemen",
		dial_code: "+967",
		code: "YE",
	},
	{
		name: "Zambia",
		dial_code: "+260",
		code: "ZM",
	},
	{
		name: "Zimbabwe",
		dial_code: "+263",
		code: "ZW",
	},
];

export const filterObject = {
	datePosted: {
		Last24hours: "Last 24 hours",
		Last3Days: "Last 3 days",
		Last7days: "Last 7 days",
		Last14days: "Last 14 days",
	},
	jobTypes: {
		fullTime: "Full Time",
		partTime: "Part Time",
		contract: "Contract",
		internShip: "Internship",
	},
	company: {
		microSoft: "Microsoft",
		apple: "Apple",
		android: "Android",
		bussines: "Bussines",
	},
};

export const applicantStatus = [
	// { value: "applied", label: "Applied" },
	{ value: "short-listed", label: "Shortlist" },
	{ value: "screened", label: "Screened" },
	{ value: "interview", label: "Interview" },
	{ value: "offer", label: "Offer" },
	{ value: "hire", label: "Hire" },
	// { value: "reject", label: "Reject" }
];

export const applicantStatus1 = [
	// { value: "applied", label: "Applied" },
	{ value: "short-listed", label: "Shortlist", show: true },
	{ value: "screened", label: "Screened", show: true },
	{ value: "interview", label: "Interview", show: true },
	{ value: "offer", label: "Offer", show: true },
	{ value: "offer accept", label: "Offer Accepted", show: false },
	{ value: "offer reject", label: "Offer Rejected", show: false },
	{ value: "offer counter", label: "Offer Countered", show: false },
	{ value: "hire", label: "Hire", show: true },
	// { value: "reject", label: "Reject" }
];

export const industryAffiliations = [
	{ value: "Accounting", label: "Accounting" },
	{ value: "Airlines/Aviation", label: "Airlines/Aviation" },
	{ value: "Alternative Dispute Resolution", label: "Alternative Dispute Resolution" },
	{ value: "Alternative Medicine", label: "Alternative Medicine" },
	{ value: "Animation", label: "Animation" },
	{ value: "Apparel & Fashion", label: "Apparel & Fashion" },
	{ value: "Architecture & Planning", label: "Architecture & Planning" },
	{ value: "Arts & Crafts", label: "Arts & Crafts" },
	{ value: "Automotive", label: "Automotive" },
	{ value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
	{ value: "Banking", label: "Banking" },
	{ value: "Biotechnology", label: "Biotechnology" },
	{ value: "Broadcast Media", label: "Broadcast Media" },
	{ value: "Building Materials", label: "Building Materials" },
	{ value: "Business Supplies & Equipment", label: "Business Supplies & Equipment" },
	{ value: "Capital Markets", label: "Capital Markets" },
	{ value: "Chemicals", label: "Chemicals" },
	{ value: "Civic & Social Organization", label: "Civic & Social Organization" },
	{ value: "Civil Engineering", label: "Civil Engineering" },
	{ value: "Commercial Real Estate", label: "Commercial Real Estate" },
	{ value: "Computer & Network Security", label: "Computer & Network Security" },
	{ value: "Computer Games", label: "Computer Games" },
	{ value: "Computer Hardware", label: "Computer Hardware" },
	{ value: "Computer Networking", label: "Computer Networking" },
	{ value: "Computer Software", label: "Computer Software" },
	{ value: "Construction", label: "Construction" },
	{ value: "Consumer Electronics", label: "Consumer Electronics" },
	{ value: "Consumer Goods", label: "Consumer Goods" },
	{ value: "Consumer Services", label: "Consumer Services" },
	{ value: "Cosmetics", label: "Cosmetics" },
	{ value: "Dairy", label: "Dairy" },
	{ value: "Defense & Space", label: "Defense & Space" },
	{ value: "Design", label: "Design" },
	{ value: "Education Management", label: "Education Management" },
	{ value: "E-learning", label: "E-learning" },
	{ value: "Electrical & Electronic Manufacturing", label: "Electrical & Electronic Manufacturing" },
	{ value: "Entertainment", label: "Entertainment" },
	{ value: "Environmental Services", label: "Environmental Services" },
	{ value: "Events Services", label: "Events Services" },
	{ value: "Executive Office", label: "Executive Office" },
	{ value: "Facilities Services", label: "Facilities Services" },
	{ value: "Farming", label: "Farming" },
	{ value: "Financial Services", label: "Financial Services" },
	{ value: "Fine Art", label: "Fine Art" },
	{ value: "Fishery", label: "Fishery" },
	{ value: "Food & Beverages", label: "Food & Beverages" },
	{ value: "Food Production", label: "Food Production" },
	{ value: "Fundraising", label: "Fundraising" },
	{ value: "Furniture", label: "Furniture" },
	{ value: "Gambling & Casinos", label: "Gambling & Casinos" },
	{ value: "Glass, Ceramics & Concrete", label: "Glass, Ceramics & Concrete" },
	{ value: "Government Administration", label: "Government Administration" },
	{ value: "Government Relations", label: "Government Relations" },
	{ value: "Graphic Design", label: "Graphic Design" },
	{ value: "Health, Wellness & Fitness", label: "Health, Wellness & Fitness" },
	{ value: "Higher Education", label: "Higher Education" },
	{ value: "Hospital & Health Care", label: "Hospital & Health Care" },
	{ value: "Hospitality", label: "Hospitality" },
	{ value: "Human Resources", label: "Human Resources" },
	{ value: "Import & Export", label: "Import & Export" },
	{ value: "Individual & Family Services", label: "Individual & Family Services" },
	{ value: "Industrial Automation", label: "Industrial Automation" },
	{ value: "Information Services", label: "Information Services" },
	{ value: "Information Technology & Services", label: "Information Technology & Services" },
	{ value: "Insurance", label: "Insurance" },
	{ value: "International Affairs", label: "International Affairs" },
	{ value: "International Trade & Development", label: "International Trade & Development" },
	{ value: "Internet", label: "Internet" },
	{ value: "Investment Banking/Venture", label: "Investment Banking/Venture" },
	{ value: "Investment Management", label: "Investment Management" },
	{ value: "Judiciary", label: "Judiciary" },
	{ value: "Law Enforcement", label: "Law Enforcement" },
	{ value: "Law Practice", label: "Law Practice" },
	{ value: "Legal Services", label: "Legal Services" },
	{ value: "Legislative Office", label: "Legislative Office" },
	{ value: "Leisure & Travel", label: "Leisure & Travel" },
	{ value: "Libraries", label: "Libraries" },
	{ value: "Logistics & Supply Chain", label: "Logistics & Supply Chain" },
	{ value: "Luxury Goods & Jewelry", label: "Luxury Goods & Jewelry" },
	{ value: "Machinery", label: "Machinery" },
	{ value: "Management Consulting", label: "Management Consulting" },
	{ value: "Maritime", label: "Maritime" },
	{ value: "Marketing & Advertising", label: "Marketing & Advertising" },
	{ value: "Market Research", label: "Market Research" },
	{ value: "Mechanical or Industrial Engineering", label: "Mechanical or Industrial Engineering" },
	{ value: "Media Production", label: "Media Production" },
	{ value: "Medical Device", label: "Medical Device" },
	{ value: "Medical Practice", label: "Medical Practice" },
	{ value: "Mental Health Care", label: "Mental Health Care" },
	{ value: "Military", label: "Military" },
	{ value: "Mining & Metals", label: "Mining & Metals" },
	{ value: "Motion Pictures & Film", label: "Motion Pictures & Film" },
	{ value: "Museums & Institutions", label: "Museums & Institutions" },
	{ value: "Music", label: "Music" },
	{ value: "Nanotechnology", label: "Nanotechnology" },
	{ value: "Newspapers", label: "Newspapers" },
	{ value: "Nonprofit Organization Management", label: "Nonprofit Organization Management" },
	{ value: "Oil & Energy", label: "Oil & Energy" },
	{ value: "Online Publishing", label: "Online Publishing" },
	{ value: "Outsourcing/Offshoring", label: "Outsourcing/Offshoring" },
	{ value: "Package/Freight Delivery", label: "Package/Freight Delivery" },
	{ value: "Packaging & Containers", label: "Packaging & Containers" },
	{ value: "Paper & Forest Products", label: "Paper & Forest Products" },
	{ value: "Performing Arts", label: "Performing Arts" },
	{ value: "Pharmaceuticals", label: "Pharmaceuticals" },
	{ value: "Philanthropy", label: "Philanthropy" },
	{ value: "Photography", label: "Photography" },
	{ value: "Plastics", label: "Plastics" },
	{ value: "Political Organization", label: "Political Organization" },
	{ value: "Primary/Secondary", label: "Primary/Secondary" },
	{ value: "Printing", label: "Printing" },
	{ value: "Professional Training", label: "Professional Training" },
	{ value: "Program Development", label: "Program Development" },
	{ value: "Public Policy", label: "Public Policy" },
	{ value: "Public Relations", label: "Public Relations" },
	{ value: "Public Safety", label: "Public Safety" },
	{ value: "Publishing", label: "Publishing" },
	{ value: "Railroad Manufacture", label: "Railroad Manufacture" },
	{ value: "Ranching", label: "Ranching" },
	{ value: "Real Estate", label: "Real Estate" },
	{ value: "Recreational", label: "Recreational" },
	{ value: "Facilities & Services", label: "Facilities & Services" },
	{ value: "Religious Institutions", label: "Religious Institutions" },
	{ value: "Renewables & Environment", label: "Renewables & Environment" },
	{ value: "Research", label: "Research" },
	{ value: "Restaurants", label: "Restaurants" },
	{ value: "Retail", label: "Retail" },
	{ value: "Security & Investigations", label: "Security & Investigations" },
	{ value: "Semiconductors", label: "Semiconductors" },
	{ value: "Shipbuilding", label: "Shipbuilding" },
	{ value: "Sporting Goods", label: "Sporting Goods" },
	{ value: "Sports", label: "Sports" },
	{ value: "Staffing & Recruiting", label: "Staffing & Recruiting" },
	{ value: "Supermarkets", label: "Supermarkets" },
	{ value: "Telecommunications", label: "Telecommunications" },
	{ value: "Textiles", label: "Textiles" },
	{ value: "Think Tanks", label: "Think Tanks" },
	{ value: "Tobacco", label: "Tobacco" },
	{ value: "Translation & Localization", label: "Translation & Localization" },
	{ value: "Transportation/Trucking/Railroad", label: "Transportation/Trucking/Railroad" },
	{ value: "Utilities", label: "Utilities" },
	{ value: "Venture Capital", label: "Venture Capital" },
	{ value: "Veterinary", label: "Veterinary" },
	{ value: "Warehousing", label: "Warehousing" },
	{ value: "Wholesale", label: "Wholesale" },
	{ value: "Wine & Spirits", label: "Wine & Spirits" },
	{ value: "Wireless", label: "Wireless" },
	{ value: "Writing & Editing", label: "Writing & Editing" },
];

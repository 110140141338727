import React, { lazy, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { USER_ROLE } from "./app/enums";
import CompanyProfile from "./pages/employerProtected/CompanyProfile";
import NewPassword from "./pages/NewPassword";
const Layout = lazy(() => import("./containers/Layout"));
const EmployerLayout = lazy(() => import("./employerContainers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Email = lazy(() => import("./pages/EmialVerified"));
const LogOut = lazy(() => import("../src/pages/employerProtected/LogOut"));
const LandingPagee = lazy(() => import("./pages/prepermotions/pages/LandingPagee"));
const VerificationPage = lazy(() => import("./pages/prepermotions/pages/VerificationPage"));
const JobDetailPublic = lazy(() => import("./pages/JobDetailPublic"));
const About = lazy(() => import("./pages/prepermotions/pages/aboutus"));
const PrivacyPolicy = lazy(() => import("./pages/prepermotions/pages/privacypolicy"));
const TermsofService = lazy(() => import("./pages/prepermotions/pages/terms-of-service"));
const Blog = lazy(() => import("./pages/prepermotions/pages/blogs"));
const BlogPage = lazy(() => import("./pages/prepermotions/pages/blogpage"));
const Contact = lazy(() => import("./pages/prepermotions/pages/contactus"));
const FAQ = lazy(() => import("./pages/prepermotions/pages/faq"));

import { useDispatch } from "react-redux";
import { getAllJobs } from "./Redux/actions/Jobs";
import { getAllEmployers } from "./Redux/actions/Employer";

initializeApp();
const { token, user, status } = checkAuth();

function App() {
	const dispatch = useDispatch();
	// Register the service worker
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.register("/firebase-messaging-sw.js")
			.then((registration) => {
				console.log("Service Worker registered with scope:", registration.scope);

				// Send the token to the service worker when the page loads or the user logs in
				if (localStorage.getItem("authToken")) {
					registration.active.postMessage({
						type: "SET_TOKEN",
						token: localStorage.getItem("token"),
					});
				}
			})
			.catch((error) => {
				console.error("Service Worker registration failed:", error);
			});
	}

	useEffect(() => {
		dispatch(getAllJobs());
		dispatch(getAllEmployers());
		document.querySelector("html").setAttribute("data-theme", "bumblebee");
		themeChange(false);
	}, [dispatch]);

	return (
		<>
			<Router>
				<Routes>
					<Route
						path="/"
						element={
							token ? (
								<Navigate
									to={
										user === USER_ROLE.SEEKER
											? "seeker/dashboard"
											: status == "approved"
											? "employer/dashboard"
											: "employer/legitimacy-checkpoint"
									}
									replace
								/>
							) : window.location.pathname.includes("/seeker") ||
							  window.location.pathname.includes("/employer") ? (
								<Navigate to={"login"} replace />
							) : (
								<LandingPagee />
							)
						}
					/>
					<Route path="/login" element={token ? <Navigate to="/" /> : <Login />} />
					<Route path="/register" element={token ? <Navigate to="/" /> : <Register />} />
					<Route path="/forgot-password" element={token ? <Navigate to="/" /> : <ForgotPassword />} />
					<Route path="/new-password" element={token ? <Navigate to="/" /> : <NewPassword />} />
					<Route path="/EmailVerification" element={<Email />} />
					<Route path="/employer/LogOut" element={<LogOut />} />
					<Route path="/emailverification-permotion" element={<VerificationPage />} />
					<Route path="/job-detail/:slug" element={<JobDetailPublic />} />
					<Route path="/blog" element={token ? <Navigate to="/" /> : <Blog />} />
					<Route path="/blog/:slug" element={<BlogPage />} />
					<Route path="/privacypolicy" element={token ? <Navigate to="/" /> : <PrivacyPolicy />} />
					<Route path="/termsofservice" element={token ? <Navigate to="/" /> : <TermsofService />} />
					<Route path="/faq" element={token ? <Navigate to="/" /> : <FAQ />} />
					<Route path="/about" element={token ? <Navigate to="/" /> : <About />} />
					<Route path="/contact" element={token ? <Navigate to="/" /> : <Contact />} />

					{/* <Route path="/under-varification" element={<UnderVarification />} /> */}
					{/* <Route path="/legitimacy-checkpoint" element={<CompanyProfile />} /> */}

					{/* Place new routes over this */}
					{user === USER_ROLE.SEEKER && <Route path="seeker/*" element={<Layout />} />}
					{user === USER_ROLE.EMPLOYER && <Route path="employer/*" element={<EmployerLayout />} />}

					{/* <Route path="seeker/*" element={<Layout />} /> 
              <Route path="employer/*" element={<EmployerLayout />} /> */}

					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
